/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../Shared/Constant/Constant';
import { authSelector } from '../../store/feature/authSlice';
import {
  getAllListings,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import { check_login } from '../../constant/check_auth';
import { socialFeedListings } from '../../store/feature/storeSlice';
import DynamicListingCard from '../Shared/Cards/DynamicListingCard';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { responsive_design_styles } from '../../utils/ResponsiveDesign';
import { useScreenSize } from '../../hooks/useScreenSize';

const ListingsList = ({
  listing_card_type,
  Products,
  like_listing,
  is_hide_like_btn = false,
  extra_children_item = undefined,
}) => {
  const { width, height } = useScreenSize();
  const { MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  const dispatch = useDispatch();
  const router = useRouter();

  //
  const like = (id, isLiked) => {
    if (check_login(router)) {
      if (like_listing) {
        like_listing(id, isLiked);
      } else {
        dispatch(
          listingLike({
            id,
            isLiked,
          })
        ).then((res) => {
          if (!res.payload.code) {
            dispatch(
              socialFeedListings({
                prams: { ...router.query, status: 2 },
              })
            );
          }
        });
      }
    }
  };
  //
  const listing_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card;
  const dynamic_card =
    listing_card_type === 'default' || listing_card_type === undefined
      ? TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_listing_card
      : TYPE_CONSTANT.DYNAMIC_STYLES?.[listing_card_type];
  const list_styles = responsive_design_styles({
    width,
    parent_group: dynamic_card?.styles,
    child_group_name: 'list_styles',
  });

  return (
    <div
      className={
        listing_card_styles?.card_type?.type !== 'custom_card'
          ? 'grid  grid-cols-1   xxs:grid-cols-[repeat(auto-fill,minmax(calc(var(--listingCardMinWidth)*var(--listingCardPerRow)/var(--listingCardPerRow)),1fr))] gap-5 justify-center'
          : ' '
      }
      style={
        listing_card_styles?.card_type?.type == 'custom_card'
          ? {
              display: 'grid',
              gridTemplateColumns: `repeat(${list_styles?.gridTemplateColumns}, minmax(0, 1fr))`,
              gap: list_styles?.gap,
            }
          : {}
      }
    >
      {Products?.map((item) => (
        <div key={Math.random()} className=" w-full relative">
          <DynamicListingCard
            product={item}
            like={like}
            MARKETPLACE_MODULES={MARKETPLACE_MODULES}
            is_hide_like_btn={is_hide_like_btn}
            listing_card_type={listing_card_type}
          />
          {extra_children_item && extra_children_item}
        </div>
      ))}
    </div>
  );
};

export default ListingsList;
