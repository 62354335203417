import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  DirectionsRenderer,
  Polyline,
  DirectionsService,
} from '@react-google-maps/api';
import { useRouter } from 'next/router';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import CustomLoading from '../../Shared/Loading/CustomLoading';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';

const ListingMapByLocationAttribute = ({ styles, attribute, data }) => {
  const containerStyle = {
    width: '100%',
    height: '100%',
  };
  const [directions, setDirections] = useState(null);

  const location = {
    lat: attribute?.values?.[0]?.latitude,
    lng: attribute?.values?.[0]?.longitude,
  };

  //
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      TYPE_CONSTANT.ADDRESS_CONFIGS?.google_map_api_key ??
      'AIzaSyBr3Q8BS3eMq4dA0u0wrkUtW_VWgYLbtB0',
  });

  const defaultCenter = { lat: 0, lng: 0 };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={get_dynamic_styles(styles?.main_styles)}
      center={location?.lat && location?.lng ? location : defaultCenter}
      zoom={8}
    >
      {location?.lat && (
        <Marker icon={data?.location_icon} position={location} />
      )}
    </GoogleMap>
  ) : (
    <CustomLoading />
  );
};

export default ListingMapByLocationAttribute;
