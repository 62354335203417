/* eslint-disable react/prop-types */
import React from 'react';
import EventButtons from '../EventsButtons/EventButtons';
import favorite from '../../../assets/Images/Home/favourite@3x.png';
import heartIcon from '../../../assets/Images/Home/heartIcon@3x.png';
import Image from 'next/image';
import * as constant from '../../Shared/Constant/TextConstant/listingDetailsConstant';
import { useSelector } from 'react-redux';
import { configsSelector } from '../../../store/feature/configsSlice';
import { dynamic_translation_set } from '../../../constant/Translation/translation_set';
import { Listing_text } from '../../../constant/Translation/Listing';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { get_dynamic_styles_with_default_value } from '../../../constant/DynamicStylesWithDefaultValue';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../constant/default_styles/ProductDetailsStyles';

const MainBox = ({ listing_details, rating_data, like, styles, data }) => {
  const { MARKETPLACE_MODULES, listings_configs } =
    useSelector(configsSelector);

  return listing_details !== null ? (
    <div
      className=" w-full  "
      style={get_dynamic_styles_with_default_value(
        styles?.main_styles,
        CardMainDefaultStyles
      )}
    >
      <div
        className="relative"
        style={get_dynamic_styles_with_default_value(
          styles?.box_styles,
          CardBoxDefaultStyles
        )}
      >
        {listings_configs?.enable_stock &&
          listing_details?.order_type === 'event' &&
          listing_details?.order_type !== 'appointments' &&
          listing_details?.order_type !== 'donation' &&
          listing_details?.order_type !== 'digital' &&
          (listing_details?.stock !== 0 ? (
            <p className="mb-2 text-sm text-info font-medium">
              {dynamic_translation_set(
                Listing_text.product.stock_text,
                listing_details?.stock
              )}
            </p>
          ) : (
            <p className=" text-sm  text-warning font-medium">
              {Listing_text.product.out_of_stock}
            </p>
          ))}
        <h1 className="  title-h1 w-5/6">{listing_details?.title}</h1>

        {Number(rating_data.rating_average) > 0 && (
          <p className="flex justify-start items-center mt-2 ">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99967 10.3892L3.78059 12.1687C3.4153 12.3706 2.97802 12.0625 3.04521 11.6506L3.66634 7.84278L1.01255 5.12285C0.728341 4.83156 0.892609 4.34088 1.29491 4.27941L4.93956 3.72256L6.54705 0.297803C6.72701 -0.0855992 7.27234 -0.0855992 7.4523 0.297803L9.05979 3.72256L12.7044 4.27941C13.1067 4.34088 13.271 4.83156 12.9868 5.12285L10.333 7.84278L10.9541 11.6506C11.0213 12.0625 10.584 12.3706 10.2188 12.1687L6.99967 10.3892Z"
                fill="#FFBA49"
              />
            </svg>
            <span className=" text-[#4F4F4F] font-medium text-base ltr:ml-2 rtl:mr-2 ">
              {rating_data.rating_average}
            </span>
            <span className=" text-default_gray  text-base font-medium ltr:ml-4 rtl:mr-4 ">
              {rating_data.rating_count}
            </span>
            <span className=" text-default_gray  text-base font-medium ltr:ml-2 rtl:mr-2  ">
              {Listing_text.product.ratings}
            </span>
          </p>
        )}
        {TYPE_CONSTANT.LISTINGS_CONFIGS?.price != false && (
          <div className=" mt-4 flex items-center flex-wrap gap-3">
            <p className=" text-black text-xl font-medium  ">
              {listing_details?.offer_price.formatted}
            </p>
            {listing_details?.offer_percent > 0 && (
              <p className="   flex items-center flex-wrap gap-2">
                <span className="text-base leading-4 font-normal text-gray-600  line-through ">
                  {listing_details.list_price.formatted}
                </span>
                <span className="text-error  ">
                  {listing_details?.offer_percent} %
                </span>
              </p>
            )}
          </div>
        )}

        {listing_details?.listing_taxes?.length > 0 && (
          <div className=" flex flex-col gap-2 mt-2">
            {listing_details?.listing_taxes?.map((it) => {
              return (
                <span className="text-base leading-4 font-normal text-gray-600   ">
                  +{it.default_value}% ({it.note})
                </span>
              );
            })}
          </div>
        )}

        {/*  */}
        {data?.wish_enabled !== false && (
          <div className=" absolute top-0 ltr:right-0 rtl:left-0 mt-4 ltr:mr-6 rtl:ml-6">
            <button
              className=" h-12 w-12 relative cursor-pointer "
              onClick={() => like(listing_details?.id, listing_details?.liked)}
            >
              {listing_details?.liked ? (
                <Image
                  src={favorite}
                  alt="follow button"
                  layout="fill"
                  objectFit="cover"
                />
              ) : (
                <Image
                  src={heartIcon}
                  alt="follow button"
                  layout="fill"
                  objectFit="cover"
                />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className=" min-h-min border bg-[#3B3269] bg-opacity-[10%] shadow rounded-card p-4   w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4 mt-5">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
      <div className="animate-pulse flex space-x-4 mt-5">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded"></div>
            <div className="h-4 bg-[#3B3269] bg-opacity-[20%] rounded-card  w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBox;
