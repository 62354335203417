import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { replaceSpecificStyles } from '../../../utils/replaceSpacificStyles';
import { Account_text } from '../../../constant/Translation/Account';

const AccountFollowButton = ({ account, styles }) => {
  return (
    <div
      className="    w-full  "
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <button
        className="border"
        style={get_dynamic_styles(
          account.following
            ? styles?.button_styles
            : replaceSpecificStyles({
                styles: styles?.button_styles,
                remove_keys: {
                  background: 'transparent',
                  text_color: styles?.button_styles?.background,
                },
                initial_values: {
                  border_color: styles?.button_styles?.background,
                },
              })
        )}
        onClick={() => follow(account?.id, account.following)}
      >
        {account.following
          ? Account_text.storelist.following
          : Account_text.storelist.follow}
      </button>
    </div>
  );
};

export default AccountFollowButton;
