import React from 'react';
import { useSelector } from 'react-redux';
import { categorySelector } from '../../store/feature/categorySlice';
import { fetch_category_subcategories } from '../../constant/fetch_all_categories';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { replaceSpecificStyles } from '../../utils/replaceSpacificStyles';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const FiltersBySubCategory = ({ data, styles }) => {
  const router = useRouter();

  const current_category = router.query.category_id;

  const { category_details } = useSelector(categorySelector);

  const subCategories = fetch_category_subcategories(
    category_details,
    data?.show_parent_category !== undefined
      ? data?.show_parent_category
      : true,
    data?.join_with !== undefined ? data?.join_with : ' → ',
    data?.parent_label !== undefined ? data?.parent_label : true
  );
  return (
    <div
      style={{
        display: styles?.main_styles?.display,
        gridTemplateColumns: dynamic_text_convert({
          main_text: styles?.main_styles?.columns_box_percentage,
          check_by: '-1%',
          value: '',
        }),
        ...get_dynamic_styles(styles?.main_styles),
      }}
    >
      {subCategories.map((subCat) => (
        <Link
          key={subCat.id}
          href={{
            pathname: `/lc/${category_details.slug}`,
            query: { category_id: subCat.id },
          }}
        >
          <a
            className="border"
            style={get_dynamic_styles(
              subCat.id == current_category
                ? styles?.button_styles
                : replaceSpecificStyles({
                    styles: styles?.button_styles,
                    remove_keys: {
                      background: 'transparent',
                      text_color: styles?.button_styles?.background,
                    },
                    initial_values: {
                      border_color: styles?.button_styles?.background,
                    },
                  })
            )}
          >
            {subCat.name}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default FiltersBySubCategory;
