/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { memo } from 'react';
import Link from 'next/link';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { listingLike } from '../../store/feature/listingSlice';

import { check_login } from '../../constant/check_auth';
import { Home_page_text } from '../../constant/Translation/Home';

import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { getListingCollections } from '../../store/feature/Collections/CollectionsSlice';
import ListingsList from '../Listings/ListingsList';
import ListingsCarousel from '../Listings/ListingsCarousel';
import ListingDynamicSkeleton from '../Shared/skeletonUI/Listing/ListingDynamicSkeleton';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const ListingCollection = ({ product_collection, styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const handleLike = async (id, isLiked) => {
    if (!check_login(router)) return;

    try {
      const res = await dispatch(
        listingLike({
          id,
          isLiked,
        })
      );

      if (!res.payload.code) {
        dispatch(getListingCollections({ bodyParam: { scope_type: '4' } }));
      }
    } catch (error) {
      console.error('Error liking listing:', error);
    }
  };

  const renderHeader = () => (
    <div className="flex justify-between items-center mb-4">
      <h2 className="heading-h2">
        {data?.title
          ? get_data_by_current_language({
              data_list: data?.translated_data,
              key_name: 'data.title',
              default_data: data?.title,
            })
          : product_collection.title}
      </h2>
      <Link
        href={`/cp/${product_collection.slug || product_collection.id}`}
        passHref
      >
        <a className="block text-base text-primary font-semibold cursor-pointer">
          {Home_page_text.home.view_all}
        </a>
      </Link>
    </div>
  );

  return product_collection ? (
    <div style={get_dynamic_styles(styles?.box_styles)}>
      {data?.is_show_header !== false && renderHeader()}
      <div className="">
        {data?.listing_type === 'list' ? (
          <ListingsList
            Products={product_collection?.listings}
            like_listing={handleLike}
          />
        ) : (
          <ListingsCarousel
            Products={product_collection?.listings}
            like_listing={handleLike}
          />
        )}
      </div>
    </div>
  ) : (
    <div style={get_dynamic_styles(styles?.box_styles)}>
      <ListingDynamicSkeleton
        listings_type={data?.listing_type}
        listing_card_type={
          TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.card_type?.type
        }
      />
    </div>
  );
};

export default memo(ListingCollection);
