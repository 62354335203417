import { useState } from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import DynamicListingCardLayout from '../../DynamicListingCard/DynamicListingCardLayout';
import GridRightTypeCard from './ListingCardSections/GridRightTypeCard';
import GridTypeCard from './ListingCardSections/GridTypeCard';
import ListTypeCard from './ListingCardSections/ListTypeCard';
import TableTypeCard from './ListingCardSections/TableTypeCard';

const DynamicListingCard = ({
  product,
  like,
  is_hide_like_btn = false,
  is_load_variants = true,
  listing_card_type,
}) => {
  const listing_card_styles = TYPE_CONSTANT.STYLES_CONFIGS?.listing_card;

  let cardComponent;

  //

  switch (listing_card_styles?.card_type?.type) {
    case 'list_card':
      cardComponent = (
        <ListTypeCard
          product={product}
          like={like}
          is_hide_like_btn={is_hide_like_btn}
        />
      );
      break;
    case 'table_card':
      cardComponent = (
        <TableTypeCard
          product={product}
          like={like}
          is_hide_like_btn={is_hide_like_btn}
        />
      );
      break;

    case 'flex_card_left_image':
      cardComponent = (
        <GridTypeCard
          product={product}
          like={like}
          is_hide_like_btn={is_hide_like_btn}
        />
      );
      break;

    case 'flex_card_right_image':
      cardComponent = (
        <GridRightTypeCard
          product={product}
          like={like}
          is_hide_like_btn={is_hide_like_btn}
        />
      );
      break;

    case 'custom_card':
      cardComponent = (
        <DynamicListingCardLayout
          product={product}
          like={like}
          is_load_variants={is_load_variants}
          listing_card_type={listing_card_type}
        />
      );
      break;

    default:
      cardComponent = (
        <ListTypeCard
          product={product}
          like={like}
          is_hide_like_btn={is_hide_like_btn}
        />
      );
      break;
  }

  return <>{cardComponent}</>;
};

export default DynamicListingCard;
