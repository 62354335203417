import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  clearListings,
  getAllListings,
  listingLike,
  listingSelector,
} from '../../store/feature/listingSlice';
import Listings from '../Listings/Listings';
import ReactPaginate from 'react-paginate';
import CustomLoading from '../Shared/Loading/CustomLoading';
import { Listing_text } from '../../constant/Translation/Listing';
import ListingsList from '../Listings/ListingsList';
import { check_login } from '../../constant/check_auth';
import NotFoundAlert from '../Shared/Default UI/NotFoundAlert';
import Pagination from '../Shared/Pagination/Pagination';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { categorySelector } from '../../store/feature/categorySlice';
import FiltersBySubCategory from '../CustomFilter/FiltersBySubCategory';

const DynamicListingsByCategory = ({ data, styles }) => {
  const router = useRouter();
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();

  const { category_details } = useSelector(categorySelector);

  //
  const fetch_params = { ...router.query, status: 2 };

  if (data?.attributes_includes) {
    fetch_params.include = 'attributes';
  }

  // get listings
  useEffect(() => {
    if (category_details?.id) {
      dispatch(
        getAllListings({
          prams: {
            ...fetch_params,
            category_id: fetch_params?.category_id ?? category_details?.id,
          },
        })
      );
    }
  }, [router, category_details]);

  // get more listings by page
  const moreListings = (next_page) => {
    router.push({
      query: {
        ...fetch_params,
        page: next_page,
      },
    });
  };

  // listings from store:
  const { listings, total_records, page, isFetching } =
    useSelector(listingSelector);

  // Count and set total page
  useEffect(() => {
    const totalpage = Math.ceil(total_records / 30);
    if (Number(total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [total_records]);

  //like
  const like = (id, isLiked) => {
    if (check_login(router)) {
      dispatch(
        listingLike({
          id,
          isLiked,
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            getAllListings({
              prams: { ...router.query, status: 2 },
            })
          );
        }
      });
    }
  };

  return (
    <>
      <div
        className="w-full   "
        style={get_dynamic_styles(styles?.main_styles)}
      >
        <div
          className="w-full   "
          style={get_dynamic_styles(styles?.box_styles)}
        >
          {isFetching && <CustomLoading />}
          {listings === null || listings?.length > 0 ? (
            <ListingsList
              listing_card_type={data?.listing_card_type}
              Products={listings}
              like_listing={like}
            />
          ) : (
            <NotFoundAlert
              alert_text={Listing_text.productlist.no_products_found}
            />
          )}
          {pageCount > 1 && (
            <div className="mt-12   flex justify-center pb-12 ">
              {listings !== null && (
                <Pagination
                  current_page={page}
                  pageCount={pageCount}
                  moreData={moreListings}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DynamicListingsByCategory;
