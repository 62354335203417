import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';

const AccountOwnerName = ({ account, styles }) => {
  return (
    <div
      className="    w-full  "
      style={get_dynamic_styles(styles?.main_styles)}
    >
      <p style={get_dynamic_styles(styles?.text_styles)}>
        {account?.user?.first_name} {account?.user?.last_name}
      </p>
    </div>
  );
};

export default AccountOwnerName;
