/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Link from 'next/link';

// Import Swiper styles
import 'swiper/css/bundle';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import axios from 'axios';
import { check_login } from '../../constant/check_auth';
import { Home_page_text } from '../../constant/Translation/Home';
import AccountCollectionsSkeleton from '../Shared/skeletonUI/AccountsCollectionsSkeleton';

import DynamicAccountsCarousel from '../AllAccounts/Accounts/DynamicAccountsCarousel';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { getAccountCollections } from '../../store/feature/Collections/CollectionsSlice';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const AccountCollection = ({ account_collection, styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const handleFollow = async (id, isFollow) => {
    if (!check_login(router)) return;

    try {
      const response = await axios.post('/api/a/follow_account', {
        id,
        isFollow,
      });
      if (response.data) {
        dispatch(getAccountCollections({ bodyParam: { scope_type: '1' } }));
      }
    } catch (error) {
      console.error('Failed to follow account:', error);
    }
  };

  if (!account_collection) {
    return (
      <div style={get_dynamic_styles(styles?.main_styles)}>
        <div style={get_dynamic_styles(styles?.box_styles)}>
          <AccountCollectionsSkeleton />
        </div>
      </div>
    );
  }

  const collectionTitle = data?.title
    ? get_data_by_current_language({
        data_list: data?.translated_data,
        key_name: 'extra_data.title',
        default_data: data?.title,
      })
    : account_collection.title;

  const collectionPath = `/ac/${
    account_collection.slug || account_collection.id
  }`;

  return (
    <div style={get_dynamic_styles(styles?.box_styles)}>
      {data?.is_show_header !== false && (
        <div className="flex justify-between items-center mb-4">
          <h2 className="heading-h2">{collectionTitle}</h2>
          <Link
            href={collectionPath}
            className="block text-base text-primary font-semibold cursor-pointer"
          >
            {Home_page_text.home.view_all}
          </Link>
        </div>
      )}

      <div>
        <DynamicAccountsCarousel
          accounts={account_collection.accounts}
          account_follow={handleFollow}
        />
      </div>
    </div>
  );
};

export default memo(AccountCollection);
