import React from 'react';
import { get_dynamic_styles } from '../../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../../utils/dynamicTextConvert';

const GalleryViewListingsImages = ({
  images,

  styles,
}) => {
  return (
    <div
      style={{
        display: styles?.main_styles?.display,
        gridTemplateColumns: dynamic_text_convert({
          main_text: styles?.main_styles?.columns_box_percentage,
          check_by: '-1%',
          value: '',
        }),
        ...get_dynamic_styles(styles?.main_styles),
      }}
    >
      {images?.map((image, index) => (
        <img
          style={get_dynamic_styles(styles?.image_styles)}
          src={image}
          alt=""
        />
      ))}
    </div>
  );
};

export default GalleryViewListingsImages;
