import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  collectionsSelector,
  getAccountCollections,
} from '../../store/feature/Collections/CollectionsSlice';
import CustomLoading from '../Shared/Loading/CustomLoading';
import AccountCollection from './AccountCollection';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const AccountCollectionsBlock = ({ styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(getAccountCollections({ bodyParam: { scope_type: '1' } }));
  }, []);

  const { account_collections, isFetching } = useSelector(collectionsSelector);

  return (
    <div
      id="account-collection"
      style={{
        display: styles?.main_styles?.display,
        gridTemplateColumns: dynamic_text_convert({
          main_text: styles?.main_styles?.columns_box_percentage,
          check_by: '-1%',
          value: '',
        }),
        ...get_dynamic_styles(styles?.main_styles),
      }}
    >
      {isFetching && <CustomLoading />}
      {account_collections?.map((collection) => {
        return (
          <AccountCollection
            account_collection={collection}
            styles={styles}
            key={collection.id}
            data={data}
          />
        );
      })}
    </div>
  );
};

export default AccountCollectionsBlock;
