import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSelector,
  getAttributesForFilter,
  onFilterParamsUpdate,
} from '../../store/feature/filter/filterSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';
import Address_search from '../Shared/Address_search';
import DynamicAddressSearch from '../Shared/DynamicAddressSearch';
import SelectFromListDropDown from '../../DynamicUI/FormFields/SelectFromListDropdown';
import { getMainPath } from '../../utils/getMainPath';

const AttributeSingleSelectFilter = ({ filer_item, styles }) => {
  const {
    filter_type,
    title,
    translated_data,
    attribute_id,
    is_show_title,
    is_depend_on_search,
    is_connected_to_category,
  } = filer_item;
  const router = useRouter();
  const dispatch = useDispatch();
  const { attributes, filter_params, queries } = useSelector(filterSelector);

  // attribute params
  const [attribute_params, setAttributeParams] = useState({});
  useEffect(() => {
    if (filter_params?.category_ids && is_connected_to_category) {
      setAttributeParams({
        category_id: filter_params?.category_ids?.join(','),
      });
    }
  }, [filter_params?.category_ids, is_connected_to_category]);

  useEffect(() => {
    dispatch(getAttributesForFilter(attribute_params));
  }, [attribute_params]);

  //
  const filter_attribute = attributes?.find((item) => item.id == attribute_id);
  const values_ids = filter_attribute?.values?.map((vl) => vl.id) ?? [];

  //
  const onUpdateQuires = (updated_quires) => {
    if (is_depend_on_search === false) {
      router.push({
        pathname: getMainPath(router.asPath),
        query: updated_quires ?? queries,
      });
    }
  };
  //
  const filter_by_attribute_value = (value) => {
    const current_value = filter_params?.[`attribute_value_ids`] ?? [];
    const check = current_value?.find((ct) => values_ids.includes(ct));

    if (value === undefined) {
      const filter = current_value?.filter((ct) => ct != check);
      dispatch(
        onFilterParamsUpdate({
          [`attribute_value_ids`]: filter,
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else if (!current_value.includes(value) && !check) {
      dispatch(
        onFilterParamsUpdate({
          [`attribute_value_ids`]: [...current_value, value],
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    } else if (!current_value.includes(value) && check) {
      const filter = current_value?.filter((ct) => ct != check);
      dispatch(
        onFilterParamsUpdate({
          [`attribute_value_ids`]: [...filter, value],
          callOnFilterUpdate: (updated_quires) => {
            onUpdateQuires(updated_quires);
          },
        })
      );
    }
  };

  return (
    filter_attribute?.values?.length > 0 && (
      <div style={get_dynamic_styles(styles?.filter_component_style)}>
        {title && is_show_title && (
          <p style={get_dynamic_styles(styles?.filter_title_style)}>
            {get_data_by_current_language({
              data_list: translated_data,
              key_name: 'title',
              default_data: title,
            })}
          </p>
        )}
        <SelectFromListDropDown
          default_option_text={filter_attribute?.name}
          drop_down_items={filter_attribute?.values?.map((value) => ({
            label: value?.name,
            value: value?.id,
          }))}
          styles={styles}
          default_icon_image={filter_attribute?.icon_path}
          set_new_value={(value) => filter_by_attribute_value(value)}
          current_value={
            filter_params?.[`attribute_value_ids`]?.find((ct) =>
              values_ids.includes(ct)
            ) ?? ''
          }
        />
      </div>
    )
  );
};

export default AttributeSingleSelectFilter;
