import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  collectionsSelector,
  getListingCollections,
} from '../../store/feature/Collections/CollectionsSlice';
import ListingCollection from './ListingCollection';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import CustomLoading from '../Shared/Loading/CustomLoading';

const ListingCollectionsBlock = ({ styles, data }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    dispatch(getListingCollections({ bodyParam: { scope_type: '4' } }));
  }, []);

  const { listing_collections, isFetching } = useSelector(collectionsSelector);

  return (
    <div
      id="listing-collection"
      style={{
        display: styles?.main_styles?.display,
        gridTemplateColumns: dynamic_text_convert({
          main_text: styles?.main_styles?.columns_box_percentage,
          check_by: '-1%',
          value: '',
        }),
        ...get_dynamic_styles(styles?.main_styles),
      }}
    >
      {isFetching && <CustomLoading />}
      {listing_collections?.map((collection) => {
        return (
          <ListingCollection
            collection={collection}
            styles={styles}
            key={collection.id}
            data={data}
          />
        );
      })}
    </div>
  );
};

export default ListingCollectionsBlock;
