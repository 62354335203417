export const fetch_all_categories = (
  categories,
  isSentParent,
  join_with = ' → ',
  is_parent_label_first = true
) => {
  var all_categories = [];
  // 1st step
  for (let i = 0; i < categories.length; i++) {
    const element = categories[i];

    //
    if (!element.sub_category?.length > 0 || isSentParent == true) {
      all_categories.push({ name: element.name, id: element.id });
    }

    // 2nd step
    if (element.sub_category?.length > 0) {
      for (let j = 0; j < element.sub_category.length; j++) {
        const elementB = element.sub_category[j];

        if (!elementB.sub_category?.length > 0 || isSentParent == true) {
          all_categories.push({
            name: is_parent_label_first
              ? `${element.name} ${join_with} ${elementB.name}`
              : `${elementB.name} ${join_with} ${element.name}`,
            id: elementB.id,
          });
        }

        // 3rd step
        if (elementB.sub_category?.length > 0) {
          for (let k = 0; k < elementB.sub_category.length; k++) {
            const elementC = elementB.sub_category[k];

            if (!elementC.sub_category?.length > 0 || isSentParent == true) {
              all_categories.push({
                name: is_parent_label_first
                  ? `${element.name} ${join_with} ${elementB.name} ${join_with} ${elementC.name}`
                  : `${elementC.name} ${join_with} ${elementB.name} ${join_with} ${element.name}`,
                id: elementC.id,
              });
            }

            // 4th step
            if (elementC.sub_category?.length > 0) {
              for (let l = 0; l < elementC.sub_category.length; l++) {
                const elementD = elementC.sub_category[l];
                all_categories.push({
                  name: is_parent_label_first
                    ? `${element.name} ${join_with} ${elementB.name} ${join_with} ${elementC.name} ${join_with} ${elementD.name}`
                    : `${elementD.name} ${join_with} ${elementC.name} ${join_with} ${elementB.name} ${join_with} ${element.name}`,
                  id: elementD.id,
                });
              }
            }
          }
        }
      }
    }

    if (i + 1 === categories.length) {
      return all_categories;
    }
  }
};

export const category_serialization = (category) => {
  let name = [];
  for (let index = 0; index < category.hierarchy.length; index++) {
    const element = category.hierarchy[index];
    name.push(element.name);
  }
  if (name.length == category.hierarchy.length) {
    return { label: name.join(' → '), value: category.id };
  }
};

export const fetch_category_subcategories = (
  category,
  isSentParent,
  join_with = ' → ',
  is_parent_label_first = true
) => {
  var subcategories = [];

  // Include the category itself if needed
  if (isSentParent) {
    subcategories.push({ name: category.name, id: category.id });
  }

  // Check for first level subcategories
  if (category.sub_category?.length > 0) {
    category.sub_category.forEach((subCat) => {
      subcategories.push({
        name: is_parent_label_first
          ? `${category.name} ${join_with} ${subCat.name}`
          : `${subCat.name} ${join_with} ${category.name}`,
        id: subCat.id,
      });

      // Check for second level subcategories
      if (subCat.sub_category?.length > 0) {
        subCat.sub_category.forEach((subSubCat) => {
          subcategories.push({
            name: is_parent_label_first
              ? `${category.name} ${join_with} ${subCat.name} ${join_with} ${subSubCat.name}`
              : `${subSubCat.name} ${join_with} ${subCat.name} ${join_with} ${category.name}`,
            id: subSubCat.id,
          });

          // Check for third level subcategories
          if (subSubCat.sub_category?.length > 0) {
            subSubCat.sub_category.forEach((deepSubCat) => {
              subcategories.push({
                name: is_parent_label_first
                  ? `${category.name} ${join_with} ${subCat.name} ${join_with} ${subSubCat.name} ${join_with} ${deepSubCat.name}`
                  : `${deepSubCat.name} ${join_with} ${subSubCat.name} ${join_with} ${subCat.name} ${join_with} ${category.name}`,
                id: deepSubCat.id,
              });
            });
          }
        });
      }
    });
  }

  return subcategories;
};
