export var Account_text = {
  storelist: {
    stores_found: 'Accounts found',
    by: 'by',
    follow: 'Follow',
    following: 'Following',
    header_title: 'Accounts',
    no_stores_available: 'No Accounts available',
    no_stores_blocked: 'No Accounts blocked',
    no_stores_found: 'No Accounts found',
  },
  storedetail: {
    report: 'Report',
    report_reason: 'Reason',
    description: 'Description',
    shipping_method_title: 'Preferred Shipping Methods',
    ratings: 'ratings',
    your_review: 'Your review',
    read_all_reviews: 'Read all reviews',
    address_title: 'Account Address',
    get_in_touch: 'Get In Touch',
    details: 'Details',
    // csv
    account_activated_successfully:
      'Your Account has been {value} successfully',
    activate: 'Activate',
    activate_confirm_message: 'Do you wish to activate your Account?',
    activated: 'Activated',
    active: 'Active',
    active_inactive_your_store: '{value} your Account?',
    active_info:
      'Are you sure, You want to make your Account temporary {value}',
    alert_message_store_rejected:
      'Your account has been rejected due to quality reasons. Kindly update your account details.',
    alert_message_store_waiting_for_approval:
      'Your account is not approved yet. Kindly wait for the admin to approve your account to continue.',
    all: 'All',
    block: 'Block',
    block_store_message: 'Do you wish to block this Account?',
    block_store_message: 'Do you wish to block this Account?',
    block_success: 'Blocked Successfully',
    blocking: 'Blocking',
    business_register_document: 'Business registration document',
    cannot_block_store: "Couldn't able to block Account.Try again later",
    deactivate: 'Deactivate',
    deactivate_confirm_message: 'Do you wish to deactivate your Account?',
    edit_profile: 'Edit Profile',
    edit_store: 'Edit Account',
    followers: 'Followers',
    header_title: 'Account Detail',
    inactivated: 'Inactivated',
    inactive: 'InActive',
    insights: 'Insights',
    link_copied: 'Link Copied',
    no_details_found: 'No details found',
    rate_this_store: 'Rate this Account',
    ratings_and_reviews: 'Ratings and reviews',
    report_success: 'Account reported successfully',
    share_description:
      'Check out this Account on {value}.Buy, Sell, Chat to get what you wanted.',
    store_currently_unavailable: 'This Account is currently unavailable',
    store_not_available: 'Account not available',
    tell_others_think: 'Tell others what you think',
    total_followers: 'Total Followers',
    total_products: 'Total Listings',
    unblock: 'Unblock',
    upload_doc_limit_5mb: 'Upload file document Limit of 5 MB',
    view_location: 'View Location',
    write_review: 'Write a review',
    about: 'About',
    review: 'Review',
    product: 'Listing',
  },
};
