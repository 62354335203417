import { useRouter } from 'next/router';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNegotiate,
  buyerNegotiationsList,
  negotiateSelector,
} from '../../../../store/feature/negotiate/negotiateSlice';
import { listingSelector } from '../../../../store/feature/listingSlice';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { loading_icon } from '../../../Shared/Constant/Icons/AllIcons';
import { Listing_text } from '../../../../constant/Translation/Listing';

const BuyerNegotiatePart = ({}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  //
  const [negotiation_amount, setNegotiationAmount] = useState('');
  const [negotiation_message, setNegotiationMessage] = useState('');
  const currency_format_icon =
    TYPE_CONSTANT.SELECTED_CURRENCY?.format?.split('{amount}')[0];

  //
  const { listing_details } = useSelector(listingSelector);
  const { isFetching: isNegotiateFetching } = useSelector(negotiateSelector);

  //make_offer
  const make_offer = (e) => {
    dispatch(
      addNegotiate({
        listing_id: listing_details.id,
        params: {
          negotiation: {
            account_id: listing_details?.account?.id,
            price: negotiation_amount,
            message: negotiation_message,
            type: 'user',
          },
        },
      })
    ).then((res) => {
      dispatch(
        buyerNegotiationsList({
          listing_id: listing_details?.id,
        })
      );
    });
  };

  return (
    <div className="bg-white flex flex-col gap-2 p-6">
      {/* Amount */}
      <div className="flex flex-col gap-2">
        <p className="text-base font-medium text-gray-500   ">
          {Listing_text.product.negotiate_enter_amount}
        </p>
        <div className=" relative  w-full    ">
          <div className="absolute   inset-y-0 px-2 flex items-center justify-center">
            <p className="text-base   font-semibold   text-center leading-none ">
              {currency_format_icon}
            </p>
          </div>
          <input
            value={negotiation_amount}
            type="number"
            className="
                    mt-0
                    block
                    w-full
                    px-6
                    
                    rounded-input

                    border-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
            placeholder="1"
            onChange={(e) => {
              if (e.target.value >= 0) {
                setNegotiationAmount(e.target.value);
              }
            }}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </div>
      </div>

      {/* Message */}
      <div className="flex flex-col gap-2">
        <p className="text-base font-medium text-gray-500   ">
          {Listing_text.product.negotiate_enter_message}
        </p>
        <div className=" relative  w-full    ">
          <textarea
            value={negotiation_message}
            className="
                    mt-0
                    block
                    w-full
                    px-2
                    rounded-input
                    border-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
            placeholder=""
            onChange={(e) => {
              setNegotiationMessage(e.target.value);
            }}
          />
        </div>
      </div>

      {/* button */}
      <div className="flex items-center justify-end">
        <button
          className={[' max-w-[200px] w-full  btn-primary-fill  gap-2 '].join()}
          onClick={() => make_offer()}
        >
          {Listing_text.product.submit_offer}
          {isNegotiateFetching && loading_icon}
        </button>
      </div>
    </div>
  );
};

export default BuyerNegotiatePart;
