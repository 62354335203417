export var seo_text = {
  sign_in_page_title: 'Sign in',
  sign_in_page_description: 'Sign in',
  sign_up_page_title: 'Sign up',
  sign_up_page_description: 'Sign up',
  verification_page_title: 'Verification',
  verification_page_description: 'Verification',
  forget_password_page_title: 'Forget password',
  forget_password_page_description: 'Forget password',
  set_password_page_title: 'Set password',
  set_password_page_description: 'Set password',
  all_listing_page_title: 'All Listings',
  all_listing_page_description: 'All listings',
  similar_listing_page_title: 'Similar Listings',
  similar_listing_page_description: 'Similar listings',
  listing_categories_page_title: 'Listing Categories',
  listing_categories_page_description: 'All listing categories.',
  category_listings_page_title: 'Category listings',
  category_listings_page_description: 'Category listings',
  cities_page_title: 'Listing Cities',
  cities_page_description: 'Listing Cities',
  city_listings_page_title: 'Listings in {city}',
  city_listings_page_description: 'Listings in {city}',
  collection_page_title: 'Collection Listings',
  collection_page_description: 'Collection Listings',
  add_listing_page_title: 'Add Listing',
  add_listing_page_description: 'Add your new listing',
  edit_listing_page_title: 'Edit Listing',
  edit_listing_page_description: 'Edit your listing',
  checkout_page_title: 'Checkout',
  checkout_page_description: 'Checkout your product',
  checkout_success_page_title: 'Checkout Success',
  checkout_success_page_description: 'Checkout success',
  all_accounts_page_title: ' Accounts',
  all_accounts_page_description: 'All Accounts',
  all_following_accounts_page_title: 'Following Accounts',
  all_following_accounts_page_description: 'Following Accounts',
  all_blocked_accounts_page_title: 'Blocked Accounts',
  all_blocked_accounts_page_description: 'Blocked Accounts',
  my_account_page_title: 'My Account',
  my_account_page_description: 'Account details',
  add_account_page_title: 'Create Account',
  add_account_page_description: 'Create new account for upload your listings.',
  edit_account_page_title: 'Edit Account',
  edit_account_page_description: 'Edit your account.',
  edit_profile_page_title: 'Edit Profile',
  edit_profile_page_description: 'Edit your profile.',
  subscription_page_title: 'Subscription',
  subscription_page_description: 'Subscription',
  waller_page_title: 'Wallet',
  waller_page_description: 'Wallet.',
  search_page_title: 'Search Listings',
  search_page_description: 'Search your listings.',
  account_orders_page_title: 'Account Orders',
  account_orders_page_description: 'All orders of your account.',
  account_order_details_page_title: 'Account Order Details',
  account_order_details_page_description: 'Order details',
  orders_page_title: 'Orders',
  orders_page_description: 'All orders list.',
  order_details_page_title: 'Order Details',
  order_details_page_description: 'Order details',
  add_review_page_title: 'Add Review',
  add_review_page_description: 'Add your review.',
  invite_page_title: 'Invite',
  invite_page_description: 'Invite your friends.',
  payout_page_title: 'Payout',
  payout_page_description: 'Payout',
  payment_page_title: 'Payment',
  payment_page_description: 'Payment',
  wishlist_page_title: 'Wishlist',
  wishlist_page_description: 'Wishlist listings.',
  social_feed_page_title: 'Social Feed',
  social_feed_page_description: 'Social Feed',
  all_transactions_page_title: ' Transactions',
  all_transactions_page_description: 'All Transactions',
  listing_categories_page_title: 'Listing Categories',
  listing_categories_page_description: 'Listing Categories',
  review_page_title: 'Add Listing Review',
  review_page_description: 'Add Listing Review',
  address_page_title: 'My Addresses',
  address_page_description: 'All my addresses',
  layers_page_title: 'All Articles',
  layers_page_description: ' Article',
  blog_page_title: 'All Blogs',
  blog_page_description: 'All Blogs',
  admin_page_title: 'All Pages',
  admin_page_description: 'All Pages',
  layer_page_title: ' Article',
  layer_page_description: 'All articles',
  add_layer_page_title: 'Add Article',
  add_layer_page_description: 'Add article',
  feedback_page_title: 'Feedback',
  feedback_page_description: 'Feedback',
  edit_layer_page_title: 'Edit Article',
  edit_layer_page_description: 'Edit article',

  not_found_page_title: 'Not found',
  not_found_page_description: 'Not found ',
  thank_you_page_title: 'Thank you',
  thank_you_page_description: 'Thank you',

  kyc_page_description: 'KYC Details.',
  kyc_page_title: 'KYC Details',

  card_page_description: 'Cards',
  card_page_title: 'Cards',
  tag_listings_page_title: 'Listings by tag',
  tag_listings_page_description: 'Listings by tag',
  listing_collections_title: 'All Listing Collections',
  listing_collections_description: 'All Listing Collections',
  account_collections_title: 'Account Collections',
  account_collections_description: 'Account Collections',
};
